<template>
    
    <div>
        <div v-if="loggedIn == false" id="login_container">
            <login></login>
        </div>
        <div v-else>
            <div id="title" class="padding flex">
                <div style="flex: 1">
                    <h1 class="white">PrimeCalc</h1>
                    <h2 class="white">Neue Kalkulation:</h2>                
                </div>
                <router-link to="/"  class="flex">
                    <img style="margin: auto 0 auto .5em; transform: scaleX(-1);" class="invert" src="https://dashboard.pixelprime.de/assets/icons/categories/userflow_outflow.svg" alt="">
                    <div style="margin: auto">
                        <p><b class="white">Zurück</b></p>
                    </div>
                </router-link>
            </div>
            <div class="card">
                <h3 class="card_title">Neue Kalkulation erstellen</h3>
                <div class="padding">
                    <h3>Auftragsdetails:</h3>
                    <div class="grid indent">
                        <p class="marginAuto radius_small" style="margin-left: 0">Kalkulationsname:</p><input :class="{red: highlightedInputs.name}" id="name" class="padding_small" type="text" v-model="name"><b></b>
                        <p class="marginAuto radius_small" style="margin-left: 0">Bauteilname:</p><input :class="{red: highlightedInputs.part}" id="part" class="padding_small" type="text" v-model="part"><b></b>
                        <p class="marginAuto radius_small" style="margin-left: 0">Kunde:</p><input :class="{red: highlightedInputs.customer}" class="padding_small" type="text" v-model="customer"><b></b>
                        <p class="marginAuto radius_small" style="margin-left: 0">Losgröße:</p><input :class="{red: highlightedInputs.lotsize}" class="padding_small" type="text" v-model="lotsize"><b></b>
                        <p class="marginAuto radius_small" style="margin-left: 0">Lieferdatum:</p><input class="blue padding_small" type="date" v-model="deadline"><b></b>
                        <p class="marginAuto radius_small" style="margin-left: 0">Beschreibung:</p><textarea :class="{red: highlightedInputs.comment}" class="blue padding_small" type="text" v-model="comment"></textarea><b></b>
                    </div>
                    <br>
                    <div :class="{muted: previous_selected >= 0}">
                        <h3>Mit Vorlage erstellen:</h3>
                        <loading v-if="!templates_existing" :msg="'Lade Vorlagen.'"></loading>
                        <div v-else style="grid-column-gap: 1em" class="flex padding_small">
                            <p :class="{blue: !template_selected}" @click="template_selected = 0"  class="padding_small radius dark pointer">Keine verwenden</p>
                            <div v-for="t in templates_existing" :key="t" class="flex">
                                <p v-if="t != 'empty'" :class="{blue: template_selected == t}" @click="template_selected = t" class="padding_small radius dark pointer">{{dict(t)}}</p>
                            </div>
                        </div>
                    </div>
                    <br>
                    <h3>Frühere Kalkulation als Basis:</h3>
                    <div style="position: relative" class="indent">
                        <!-- <search :values="calcNames" @filtered="previous_filtered = $event"></search> -->
                        <p v-if="!previousCalcs">Lade frühere Kalkulationen.</p>
                        <div else style="grid-column-gap: 1em" class="flex">
                            <div :class="{blue: previous_selected == -1}" class="pointer padding_small dark radius" @click="previous_selected = -1">Keine verwenden</div>
                            <div v-for="(p, i) in previousCalcs" :key="i" @click="previous_selected = i" :class="{blue: previous_selected == i}" class="pointer padding_small dark radius">
                                <p>{{p.name}}</p>
                                <p v-if="previous_selected == i">{{p.description}}</p>
                            </div>
                            <p v-if="previousCalcs == 'none'">Keine früheren Kalkulationen vorhanden.</p>
                        </div>
                        <!-- <div v-if="previous_selected != -1">
                            <p>{{previousCalcs[previous_selected]}}</p>
                        </div> -->
                    </div>
                    <br><br>
                    <div>
                        <h3 class="blue pointer padding radius fitContent" @click="create()">Erstellen</h3>
                        <p>{{message}}</p>
                    </div>
                </div>
            </div>
        </div>

    </div>

</template>

<script>
import apiCall from '@/apiCall'
export default {
    name: "New",
    data() {
        return {
            name: "",
            part: "",
            customer: "",
            lotsize: "",
            deadline: "",
            comment: "",
            template_selected: "",
            previous_selected: -1,
            previousCalcs_loading: true,
            message: "",
            highlightedInputs: {}
        }
    },
    computed: {
        loggedIn() {
            return this.$store.state.loggedIn
        },
        previousCalcs() {
            if(this.$store.getters.saves === 0) this.$store.dispatch("saves_getAll")            
            return this.$store.getters.saves
        },
        calcNames() {
            if(!this.previousCalcs) return []
            let names = []
            for (const id in this.previousCalcs) {
                console.log(this.previousCalcs[id].name);
                names.push(this.previousCalcs[id].name)
            }
            return names
        },
        templates_existing() {
            return this.$store.getters.templates
        }
    },
    created() {
        // this.getTemplates()
        // this.getPreviousCalcs()
    },
    watch: {
        name() {
            if(!this.name) this.highlightedInputs.name = 1
            else delete this.highlightedInputs.name
        }
    },
    methods: {
        dict(c) {
            return this.$store.state.dict[c] || c
        },
        calcType_set(type) {
            this.calcType = type
        },
        // getPreviousCalcs() {
        //     const formData = new FormData();
        //     formData.append('method', "getPreviousCalcs");
        //     fetch(this.$store.getters.path_data, {
        //         method: "POST",
        //         body: formData
        //     }).then(result => {
        //         return result.json()
        //     }).then(result => {
        //         this.previousCalcs = result
        //         this.previousCalcs_loading = false
        //     })
        // },
        getTemplates() {
            this.templates_existing = ["turning", "milling"]
            return
            // const formData = new FormData();
            // formData.append('method', "templates_getExisting");
            // fetch(this.$store.getters.path_data, {
            //     method: "POST",
            //     body: formData
            // }).then(result => {
            //     return result.json()
            // }).then(result => {
            //     this.templates_loading = false
            //     this.templates_existing = result
            // })
        },
        create() {
            this.highlightedInputs = {}
            this.message = ""
            if (!this.name) {
                this.highlightedInputs.name = 1
                this.message += "Bitte geben Sie dem Bauteil einen Namen\n"
                document.getElementById("name").focus()
            }
            if(!this.lotsize) this.lotsize = 1
            if (!this.message) {
                this.initiateCreation()
            }
        },
        initiateCreation() {
            const id = new Date().getTime()
            const information = {
                "name": this.name,
                "part": this.part,
                "customer": {name: this.customer},
                "lotsize": this.lotsize,
                "deadline": this.deadline,
                "comment": this.comment
            }
            this.message = "Erstelle Kalkulation..."
            apiCall({
                method: "save_new",
                save: id,
                template: this.template_selected,
                previous: this.previous_selected,
                information
            }).then(response => {
                if (response.statusCode == 200) {
                    document.cookie = `calculation_loaded=${id}; expires=${new Date()+1000*60}; path=/`;
                    this.$router.push("/calculation/file")
                    this.$store.dispatch("loadCalculation")
                }
            })


            // const formData = new FormData();
            // formData.append('method', "calcData_new");
            // formData.append('information', JSON.stringify(information));
            // formData.append('template', this.template_selected);
            // if(this.previous_selected > -1) formData.append('previous', this.previous_selected);
            // fetch(this.$store.getters.path_data, {
            //     method: "POST",
            //     body: formData
            // }).then(result => {
            //     return result.json()
            // }).then(result => {
            //     this.message = result[1]
            //     this.$store.state.loadedCalculation = false
            //     this.$router.push('/calculation/file')  
            // }).catch(()=>{
            //     this.message = "Ein Fehler ist aufgetreten."
            // })
        },
    }
}
</script>

<style scoped>
    .white{color: #dedede}
    .red{background-color: #f5d1d1!important;}
    h3{ margin-bottom: 6px;}
    textarea,input{outline: none; border: none}
    #title{
        margin-bottom: 2em;
        background-color: #22252D;
        padding: 1em 8em;
        grid-column-gap: 4em;
    }
    .card{
        margin: 1em 8em;
    }
    .grid{
        display: grid;
        grid-template-columns: auto 12vw 1fr;
        grid-column-gap: 1em;
        grid-row-gap: 4px;
    }
</style>